
import getPageQuery from '~/graphql/queries/Page.graphql';
import QueryMixin from '~/mixins/query.js';
import getCraftUri from '~/util/urls.js';

export default {
    mixins: [
        QueryMixin
    ],

    fetchKey() {
        const kebabPath = this.$route.path.replace(/\//g, '-');

        return `query-page-${kebabPath}`;
    },

    async fetch() {
        const uri = getCraftUri(this, this.$route.path);

        const { data } = await this.craftQuery(
            getPageQuery,
            { uri }
        );

        await this.setEntryOrNotFound(data);
    },

    head() {
        return this.seo;
    }
};
